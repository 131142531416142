<template>
  <v-app>
    <v-row>
      <v-col cols="3">
        <v-card>
          <v-card-title>
            File Type
          </v-card-title>

          <v-divider class="mx-4" />

          <v-list-item-group
            v-model="selectedFileType"
            mandatory
          >
            <v-list-item
              v-for="(item, i) in fileTypes"
              :key="i"
              :value="item"
            >
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox
                    :on-icon="'mdi-checkbox-marked-circle'"
                    :off-icon="'mdi-checkbox-blank-circle-outline'"
                    :input-value="active"
                  />
                </v-list-item-action>

                <v-list-item-content class="mt-1">
                  <v-list-item-title style="font-size: .9rem">
                    {{ item.fileTypeName }}
                    <v-list-item-subtitle style="font-size: .7rem; text-wrap: wrap">
                      {{ item.description }}
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-card>
        <v-card
          class="mt-5"
        >
          <v-card-title>
            State
          </v-card-title>

          <v-divider class="mx-4" />

          <v-list
            style="max-height: 200px"
            class="overflow-y-auto"
          >
            <v-list-item-group
              v-model="selectedState"
              color="primary"
            >
              <v-list-item
                v-for="(item, i) in states"
                :key="i"
                :value="item"
                style="font-size: .9rem"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="getStateLogic(item)" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
        <template v-if="selectedFileType.fileTypeID === 1">
          <v-autocomplete
            v-model="selectedSpecialty"
            dense
            hide-details
            :items="specialtyResults"
            item-text="specialtyName"
            item-value="specialtyCode"
            :search-input.sync="specialtyNameSearch"
            clearable
            outlined
            label="Specialty Name"
            class="mt-5"
            color="primary"
          />
        </template>
      </v-col>
      <v-col
        class="ml-5"
        cols="8"
      >
        <v-form v-model="valid">
          <v-row
            class="mt-9"
            justify="space-between"
          >
            <v-col cols="3">
              <v-text-field
                v-model="firstName"
                :rules="[rules.required, rules.counter]"
                style="width: 250px"
                dense
                label="First Name"
                hide-details="auto"
                @keyup.enter="getDrLookup()"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="lastName"
                style="width: 250px"
                :rules="[rules.required, rules.counter]"
                dense
                label="Last Name"
                hide-details="auto"
                @keyup.enter="getDrLookup()"
              />
            </v-col>
            <v-col>
              <v-btn
                tile
                color="accent"
                :disabled="!valid"
                @click="getDrLookup()"
              >
                search
              </v-btn>
            </v-col>
            <ViewAsCustomer
              v-if="showViewAsCustomer"
              class="mt-2 mr-4"
            />
          </v-row>
        </v-form>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-data-table
                item-key="meNumber"
                class="mt-10"
                :headers="lookupHeaders"
                :items="results"
                :expanded.sync="expanded"
                show-expand
                :loading="resultsLoading"
              >
                <template v-slot:[`item.id`]="{ item }">
                  <div>
                    {{ item.mixedMENumber }}
                  </div>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <div>
                    <span>{{ item.mailName }}</span>
                  </div>
                </template>
                <template v-slot:[`item.address`]="{ item }">
                  <div>
                    <span>{{ getInitialAddress(item) }}</span>
                  </div>
                </template>
                <template v-slot:[`item.specialty`]="{ item }">
                  <div>
                    <span>{{ item.specialtyName }}</span>
                  </div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-icon
                        v-if="item.eyeColor === 'Brown'"
                        dark
                        class="mr-2"
                        color="error"
                        v-on="{ ...onTooltip }"
                      >
                        mdi-alert-circle-outline
                      </v-icon>
                    </template>
                    <span>Person omitted</span>
                  </v-tooltip>
                </template>
                <template v-slot:expanded-item="{ headers, item}">
                  <td
                    :colspan="headers.length"
                    class="px-0"
                  >
                    <DrLookupTable
                      :dr-id="item.meNumber"
                      :mixed-me="item.mixedMENumber"
                      :file-type="selectedFileType.fileTypeID"
                      :file-type-name="selectedFileType.fileTypeName"
                      :customer-number="$store.getters['simulatedCustomerNowwId']()"
                      :tenant-crm-id="viewAsCustomer()"
                    />
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-app>
</template>
<script>
import { nowwService } from '@/shared/services'
import { mapGetters } from 'vuex'
import mutationTypes from '@/store/mutation-types'

export default {
  name: 'Maintenance',

  components: {
    ViewAsCustomer: () =>
      import('@/views/pages/components/user-state/ViewAsCustomer'),
    DrLookupTable: () =>
      import('@/views/pages/dashboards/DrLookupTable')
  },

  data () {
    return {
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length >= 2 || 'Min 2 characters'
      },
      firstName: '',
      lastName: '',
      valid: false,
      allStates: [],
      selectedState: '',
      search: '',
      results: [],
      fileTypes: [],
      selectedFileType: '',
      stateResults: [],
      selectedSpecialty: '',
      specialtyResults: [],
      resultsLoading: false,
      specialtyName: '',
      specialtyNameSearch: '',
      expanded: [],
      dialog: false,
      lookupHeaders: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          class: 'title',
          value: 'id'
        },
        { text: 'Name', value: 'name', sortable: false, class: 'title' },
        { text: 'Address', value: 'address', sortable: false, class: 'title' },
        { text: 'Specialty', value: 'specialty', sortable: false, class: 'title' },
        { value: 'actions', align: 'end', sortable: false, filterable: false },
        { text: '', value: 'data-table-expand' }
      ]
    }
  },

  computed: {
    ...mapGetters(['viewAsCustomer', 'simulatedCustomerCrmId']),
    ...mapGetters('auth', [
      'isMMSRole',
      'getTenantId',
      'getTenantCrmId'
    ]),
    states () {
      if (this.results.length === 0) {
        return this.allStates
      }
      return this.getStateCounts()
    },
    showViewAsCustomer () {
      return this.$store.getters['auth/isMMSRole']()
    }
  },

  watch: {
    selectedState (newValue, oldValue) {
      if (this.results.length !== 0 & newValue !== undefined) {
        this.getDrLookup()
      }
    },
    selectedFileType (newValue, oldValue) {
      if (this.results.length !== 0 & newValue !== undefined) {
        this.getDrLookup()
      }
    },
    selectedSpecialty (newValue, oldValue) {
      if (this.results.length !== 0 & newValue !== undefined) {
        this.getDrLookup()
      }
    }
  },

  mounted () {
    this.unsubscribe = this.$store.subscribe(mutation => {
      let listeners = [
        mutationTypes.SET_VIEW_AS_CUSTOMER
      ]
      if (listeners.includes(mutation.type)) {
        this.getDrLookup()
      }
    })
  },

  created () {
    this.loadSpecialties()
    this.loadFileTypes()
    this.loadStates()
  },

  methods: {
    getStateCounts () {
      var _this = this
      var counts = {}

      // Get state counts from results
      _this.results.forEach(function (a) {
        counts[a.mailState] = (counts[a.mailState] || 0) + 1
      })

      // Map counts objects to new array and sort it
      var finalArray = Object.entries(counts)
        .map(([name, count, value]) => ({ name, count, value }))
        .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

      // Logic for no state results and first all states value
      for (var i = 0; i < finalArray.length; i++) {
        for (var j = 0; j < _this.allStates.length; j++) {
          if (finalArray[i].name === '  ') {
            finalArray[i].name = 'no state'
            finalArray[i].value = ' '
          } else if (finalArray[i].name === _this.allStates[j].value) {
            finalArray[i].name = _this.allStates[j].name
            finalArray[i].value = _this.allStates[j].value
          }
        }
      }
      var initialState = { 'value': 'NONE', 'count': '', 'name': 'All states' }
      return [initialState].concat(finalArray)
    },
    getStateLogic (item) {
      if (this.results.length === 0) {
        return item.name
      }
      return item.name + ' ' + item.count
    },
    getSpecialtyLogic () {
      var specialtyName = ''
      if (this.selectedFileType.fileTypeID !== 1) {
        specialtyName = 'NONE'
      } else if (this.selectedSpecialty === '' || this.selectedSpecialty === null) {
        specialtyName = 'NONE'
      } else {
        specialtyName = this.selectedSpecialty
      }
      return specialtyName
    },
    getInitialAddress (value) {
      // var addressResult = value.city + ', ' + value.state + ' ' + value.postalCode
      var address = `${value.mailCity}, ${value.mailState} ${value.mailZipCode}`
      return address
    },
    async loadSpecialties () {
      var _this = this
      await nowwService.getDrLookupSpecialties()
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.specialtyResults = resp.sort()
          }
        })
    },
    async loadFileTypes () {
      var _this = this
      await nowwService.getDrLookupFileTypes()
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            console.log(resp)
            _this.fileTypes = resp
          }
        })
    },
    async loadStates () {
      var _this = this
      await nowwService.getDrLookupStates()
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            var initialState = { 'value': 'NONE', 'name': 'All states' }
            _this.allStates = [initialState].concat(resp)
          }
        })
    },
    async getDrLookup () {
      var _this = this
      _this.resultsLoading = true
      var specialty = _this.getSpecialtyLogic()
      await nowwService.getDrLookup({
        tenantCrmId: _this.$store.getters['auth/getTenantCrmId'](),
        customerId: _this.$store.getters['auth/getTenantNowwId'](),
        firstName: _this.firstName,
        lastName: _this.lastName,
        fileType: _this.selectedFileType.fileTypeID,
        state: (_this.selectedState === '' || _this.selectedState === undefined) ? 'NONE' : _this.selectedState.value,
        specialty: specialty
      })
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.results = resp
            _this.resultsLoading = false
          }
        })
    }
  }
}
</script>
